import { useEffect, useState } from "react";
import axios from "../utils/axios";

const useFindUser = () => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const findUser = async () => {
            try {
                const { data } = await axios.get("/auth/me");

                setUser(data);
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };

        if (!user) {
            (async () => {
                await findUser();
            })();
        } else {
            setIsLoading(false);
        }
    }, []);

    return {
        user,
        setUser,
        isLoading,
    };
};

export default useFindUser;
