import React from "react";

import {Route, Switch} from "react-router-dom";
import PrivateRoute from "./layouts/PrivateRoute";

import {Login} from "./pages/Login";
import {EditUsers} from "./pages/EditUsers";

const AppRouter = () => {
    return (
        <Switch>
            <PrivateRoute
                exact
                path="/"
                component={EditUsers}
            />
            <Route
                exact
                path="/login"
                component={Login}
            />
        </Switch>
    );
};

export default AppRouter;
