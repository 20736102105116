// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edit-user-styles_btns__3Gtb3 {\n    margin-top: 40px;\n    display: grid;\n    grid-template-columns: repeat(2, auto);\n    grid-gap: 24px;\n}\n\n.edit-user-styles_outline__1ILoS {\n    background-color: #fff;\n    color: var(--primary-color);\n}\n", "",{"version":3,"sources":["webpack://src/components/EditUser/edit-user-styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sCAAsC;IACtC,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,2BAA2B;AAC/B","sourcesContent":[".btns {\n    margin-top: 40px;\n    display: grid;\n    grid-template-columns: repeat(2, auto);\n    grid-gap: 24px;\n}\n\n.outline {\n    background-color: #fff;\n    color: var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btns": "edit-user-styles_btns__3Gtb3",
	"outline": "edit-user-styles_outline__1ILoS"
};
export default ___CSS_LOADER_EXPORT___;
