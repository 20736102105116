// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-styles_modal__2YtQc {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    left: 0;\n    background: rgba(214, 214, 214, 0.3);\n    backdrop-filter: blur(16px);\n    -webkit-backdrop-filter: blur(16px);\n    padding: 190px 30px;\n    z-index: 60;\n    display: flex;\n}\n\n.modal-styles_inactive__3ryWL {\n    opacity: 0;\n}\n\n.modal-styles_active__3bYLN {\n    opacity: 1;\n}\n\n.modal-styles_modalContext__1e5jA {\n    margin: auto;\n    padding: 50px 75px 50px;\n    background: #fff;\n    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);\n    position: relative;\n}\n", "",{"version":3,"sources":["webpack://src/components/Modal/modal-styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,MAAM;IACN,OAAO;IACP,oCAAoC;IACpC,2BAA2B;IAC3B,mCAAmC;IACnC,mBAAmB;IACnB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,yCAAyC;IACzC,kBAAkB;AACtB","sourcesContent":[".modal {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    left: 0;\n    background: rgba(214, 214, 214, 0.3);\n    backdrop-filter: blur(16px);\n    -webkit-backdrop-filter: blur(16px);\n    padding: 190px 30px;\n    z-index: 60;\n    display: flex;\n}\n\n.inactive {\n    opacity: 0;\n}\n\n.active {\n    opacity: 1;\n}\n\n.modalContext {\n    margin: auto;\n    padding: 50px 75px 50px;\n    background: #fff;\n    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal-styles_modal__2YtQc",
	"inactive": "modal-styles_inactive__3ryWL",
	"active": "modal-styles_active__3bYLN",
	"modalContext": "modal-styles_modalContext__1e5jA"
};
export default ___CSS_LOADER_EXPORT___;
