import { useEffect, useState } from "react";

import styles from "./checkbox-styles.module.css";

const Checkbox = ({ label, name, value, checked, disabled, onChange }) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = (event) => {
        setIsChecked(event.target.checked);

        onChange(event);
    };

    useEffect(() => {
        if (checked !== isChecked) {
            setIsChecked(checked);
        }
    }, [checked]);

    return (
        <label className={`${styles.label} ${disabled ? styles.disabled : ""}`}>
            <input
                type={"checkbox"}
                name={name}
                value={value}
                defaultChecked={checked}
                onChange={handleChange}
                className={styles.input}
                disabled={disabled}
            />
            <span
                className={`${styles.checkbox} ${
                    isChecked ? styles.checked : ""
                }`}
            />
            {label}
        </label>
    );
};

export default Checkbox;
