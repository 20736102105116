import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../context";
import Header from "./Header/Header";

const PrivateRoute = (props) => {
    const { component: Component, ...rest } = props;

    const { user, isLoading } = useContext(UserContext);

    if (isLoading) {
        return <div></div>;
    } else {
        if (user && (user.is_admin || user.isAdmin)) {
            return (
                <Route
                    {...rest}
                    render={(matchProps) => (
                        <>
                            <Header />
                            <Component {...matchProps} />
                        </>
                    )}
                />
            );
        }

        return <Redirect to={"/login"} />;
    }
};

export default PrivateRoute;
