import { useHistory } from "react-router-dom";
import { useContext, useState } from "react";

import styles from "./login-styles.module.css";
import { Checkbox } from "../../shared/Checkbox";
import axios from "../../utils/axios";
import { UserContext } from "../../context";

const Login = () => {
    const history = useHistory();

    const { setUser } = useContext(UserContext);

    const [data, setData] = useState({
        email: "",
        password: "",
        rememberMe: false,
    });
    const [error, setError] = useState(false);

    const handleInputChange = (prop) => (event) => {
        setData({ ...data, [prop]: event.target.value });
    };

    const handleCloseError = () => {
        setData({
            ...data,
            ...{
                email: "",
                password: "",
            },
        });

        emptyInput("#email");
        emptyInput("#password");

        setError(false);
    };

    const emptyInput = (selector) => {
        document.querySelector(selector).value = "";
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        setError(false);

        if (data.email && data.password) {
            try {
                const res = await axios.post("/auth/login", {
                    email: data.email,
                    password: data.password,
                    rememberMe: data.rememberMe,
                });

                const user = res.data;

                if (!user.isAdmin) {
                    setError(true);

                    return;
                }

                setUser(user);
                history.push("/");
            } catch (e) {
                setError(true);
            }
        } else {
            setError(true);
        }
    };

    return (
        <div className={styles.box}>
            <div>
                {/* ID 1.4 */}
                <h2 className={styles.title}>Login</h2>

                {/*<p>(email: test@test.com, pass: test)</p>*/}

                {/* ID 1.G */}
                {error && (
                    <div className={styles.error}>
                        <img src={"/images/error.svg"} alt="" />

                        <span>
                            Incorrect username or password. <br />
                            Please try again or contact us.
                        </span>
                    </div>
                )}

                <form onSubmit={handleLogin}>
                    {/* ID 1.A */}
                    <label className={styles.label}>
                        <input
                            required
                            className={`${styles.input} ${styles.email}`}
                            type={"email"}
                            placeholder={"Enter your email"}
                            onChange={handleInputChange("email")}
                        />
                    </label>

                    {/* ID 1.B */}
                    <label className={styles.label}>
                        <input
                            required
                            className={`${styles.input} ${styles.password}`}
                            type={"password"}
                            placeholder={"Enter your password"}
                            onChange={handleInputChange("password")}
                        />
                    </label>

                    {/* ID 1.C */}

                    <Checkbox
                        label={"Remember me"}
                        value={1}
                        onChange={handleInputChange("rememberMe")}
                    />

                    {/* ID 1.D */}
                    <button className={styles.loginAction}>Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
