import axios from "../../utils/axios";

import { useEffect, useState } from "react";

import styles from "./edit-user-styles.module.css";

const EditUser = ({ user, handleUserChange, cancelChange, setResponse }) => {
    const [data, setData] = useState({
        username: "",
        email: "",
        password: "",
    });

    useEffect(() => {
        if (user) {
            setData({ ...data, ...user });
        }
    }, []);

    const handleInputChange = (prop) => (event) => {
        setData({ ...data, [prop]: event.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (user?.id) {
            try {
                const res = await axios.patch(`/users/${user.id}`, {
                    username: data.username,
                    email: data.email,
                    password: data.password.length > 0 ? data.password : null,
                });

                setResponse(res.status === 200);
            } catch (e) {
                setResponse(false);
            }
        } else {
            try {
                const res = await axios.post("/users", {
                    username: data.username,
                    email: data.email,
                    password: data.password,
                });

                setResponse(res.status === 200);
            } catch (e) {
                setResponse(false);
            }
        }

        handleUserChange();
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label>
                    Username
                    <input
                        defaultValue={user?.username ?? ""}
                        required={true}
                        type="text"
                        onChange={handleInputChange("username")}
                    />
                </label>
                <label>
                    Email
                    <input
                        defaultValue={user?.email ?? ""}
                        required={true}
                        type="email"
                        onChange={handleInputChange("email")}
                    />
                </label>
                <label>
                    Password
                    <input
                        required={user?.id ? false : true}
                        type="password"
                        onChange={handleInputChange("password")}
                    />
                </label>
                <div className={styles.btns}>
                    <button
                        type="button"
                        onClick={cancelChange}
                        className={styles.outline}
                    >
                        Cancel
                    </button>

                    <button>{user?.id ? "Update" : "Create"}</button>
                </div>
            </form>
        </div>
    );
};

export default EditUser;
