import styles from "./header-styles.module.css";
import { Link, useHistory } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context";
import axios from "../../utils/axios";

const Header = () => {
    const { user, setUser } = useContext(UserContext);

    const history = useHistory();

    const logout = async (e) => {
        e.preventDefault();

        await axios.post("/auth/logout");
        setUser(null);
        localStorage.removeItem("t");

        history.push("/login");
    };

    return (
        <>
            <header className={styles.header} id={"header"}>
                <div className={styles.wrapper}>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title}>
                            <Link to={user ? "/" : "/login"}>
                                <img src={"/logo.svg"} alt="White thesis" />
                            </Link>
                        </div>
                    </div>

                    <div className={styles.login}>
                        {user && <span>Hello, dear {user.username}. </span>}{" "}
                        <button className={styles.logout} onClick={logout}>
                            Logout
                        </button>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
