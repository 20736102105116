import { useEffect, useState } from "react";

import styles from "./edit-users-styles.module.css";
import axios from "../../utils/axios";
import { Modal } from "../../components/Modal";
import { EditUser } from "../../components/EditUser";
import { Response } from "../../shared/Response";

const EditUsers = () => {
    const [editUsers, setEditUsers] = useState([]);
    const [editUser, setEditUser] = useState(null);
    const [modalActive, setModalActive] = useState(false);

    const [response, setResponse] = useState(null);

    useEffect(() => {
        (async () => {
            await getUsers();
        })();
    }, []);

    const getUsers = async () => {
        const res = await axios.get("/users");
        setEditUsers(res.data);
    };

    const handleDelete = async (id) => {
        const res = await axios.delete(`/users/${id}`);
        await getUsers();
    };

    const handleCreate = () => {
        setEditUser(null);
        setModalActive(true);
    };

    const handleEdit = (user) => {
        setEditUser(user);
        setModalActive(true);
    };

    const handleUserChange = async () => {
        await getUsers();
        setModalActive(false);
    };

    const cancelChange = () => {
        setModalActive(false);
    };

    const Error = () => {
        return (
            <Response type={"error"} setResponse={setResponse}>
                Error!
            </Response>
        );
    };

    return (
        <div className={styles.wrapper}>
            {response === false && <Error />}

            <div className={styles.header}>
                <h2>Manage users</h2>
                <button onClick={() => handleCreate()}>Add user</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Agreement</th>
                    </tr>
                </thead>
                <tbody>
                    {editUsers.map((u) => (
                        <tr key={u.id}>
                            <td>{u.username}</td>
                            <td>{u.email}</td>
                            <td>
                                <div
                                    className={`${styles.check} ${
                                        u.agreement ? styles.active : ""
                                    }`}
                                />
                            </td>
                            <td>
                                <button onClick={() => handleEdit(u)}>
                                    Edit
                                </button>
                            </td>
                            <td>
                                <button onClick={() => handleDelete(u.id)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal active={modalActive} setActive={setModalActive}>
                <EditUser
                    user={editUser}
                    handleUserChange={handleUserChange}
                    cancelChange={cancelChange}
                    setResponse={setResponse}
                />
            </Modal>
        </div>
    );
};

export default EditUsers;
