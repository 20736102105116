import useFindUser from "./hooks/useFindUser";
import {UserContext} from "./context";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./router";

const App = () => {
    const {user, setUser, isLoading} = useFindUser();

    return (
        <UserContext.Provider value={{user, setUser, isLoading}}>
            <BrowserRouter>
                <AppRouter/>
            </BrowserRouter>
        </UserContext.Provider>
    );
};

export default App;
