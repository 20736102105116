import styles from "./modal-styles.module.css";

const Modal = ({ active, setActive, children }) => {
    return (
        active && (
            <div className={styles.modal}>
                <div
                    className={styles.modalContext}
                    onClick={(e) => e.stopPropagation()}
                >
                    {children}
                </div>
            </div>
        )
    );
};

export default Modal;
