import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("t");
    if (!token) {
        return config;
    }
    config.headers.Authorization = `Bearer ${localStorage.getItem("t")}`;

    return config;
});

// Response interceptor for API calls
// instance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     async function (err) {
//         const originalConfig = err.config;
//
//         if (
//             localStorage.getItem("t") &&
//             err.response.status === 401 &&
//             originalConfig.url.indexOf("/auth/me") > -1
//         ) {
//             localStorage.removeItem("t");
//
//             window.location.href = "/login";
//             return Promise.reject(err);
//         }
//
//         if (
//             originalConfig.url !== "/login" &&
//             err.response &&
//             err.response.status === 401 &&
//             !originalConfig._retry
//         ) {
//             originalConfig._retry = true;
//
//             try {
//                 const res = await instance.get("/auth/me");
//
//                 localStorage.setItem("t", res.data);
//
//                 axios.defaults.headers.common["Authorization"] =
//                     "Bearer " + res.data;
//
//                 return instance(originalConfig);
//             } catch (error) {
//                 window.location.href = "/login";
//                 return Promise.reject(error);
//             }
//         }
//
//         return Promise.reject(err);
//     }
// );

export default instance;
