import { createContext, useState } from "react";

export const HomepageContext = createContext({
    section: null,
    setSection: () => {},
});

export const HomepageProvider = ({ children }) => {
    const [section, setSection] = useState(null);

    return (
        <HomepageContext.Provider value={{ section, setSection }}>
            {children}
        </HomepageContext.Provider>
    );
};
